<template>
  <div class="uk-grid-medium" uk-grid>
    <div class="uk-width-5-5">
      <div class="d-flex">
        <nav id="breadcrumbs" class="mb-3">
          <ul>
            <li>
              <router-link :to="{ name: 'company.dashboard' }">
                <i class="uil-home-alt"></i>
              </router-link>
            </li>
            <li>
              {{ this.$t('menu.company_dashboard.assignments') }}
            </li>
            <li>{{ this.$t('general.all_assignments') }}</li>
          </ul>
        </nav>
      </div>

      <div class="section-header mb-lg-2 border-0 uk-flex-middle">
        <div class="section-header-left">
          <h2 class="uk-heading-line text-left">
            <span> {{ this.$t('general.all_assignments') }} ({{ items.total }}) </span>
          </h2>
        </div>
        <div class="section-header-right">
          <router-link :to="{ name: 'company.assignments.add' }">
            <button class="btn btn-default" type="button" aria-expanded="false">
              <i class="uil-location-arrow"></i> {{ this.$t('menu.company_dashboard.create_assignment') }}
            </button>
          </router-link>
        </div>
      </div>

      <template v-if="errorMessages">
        <div v-for="(error, index) in errorMessages" :key="index" class="uk-alert-danger" uk-alert>
          <a class="uk-alert-close" uk-close></a>
          <p style="color: red">
            <i class="uil-exclamation-triangle"></i> {{ error }}
          </p>
        </div>
      </template>
      <div v-if="successMessage" class="uk-alert-success" uk-alert>
        <a class="uk-alert-close" uk-close></a>
        <p style="color: green">
          <i class="uil-exclamation-triangle"></i> {{ successMessage }}
        </p>
      </div>
      <div class="section-header pb-0">
        <div class="section-header-left">
          <input class="mt-3" type="text" :placeholder="$t('general.search_in_contents')" v-model="filter.search" />
        </div>
        <div class="section-header-right">
          <div class="btn-group bootstrap-select ml-3">
            <select class="selectpicker4 ml-1" @change="sortOrder">
              <option value="name" data-sort="asc">{{ this.$t("sorting.a_z") }}</option>
              <option value="name" data-sort="desc">{{ this.$t("sorting.z_a") }}</option>
              <option value="created_at" data-sort="asc">{{ this.$t("sorting.created_at_asc") }}</option>
              <option value="created_at" data-sort="desc" selected>{{ this.$t("sorting.created_at_desc") }}</option>
              <option value="end_date" data-sort="asc">Bitiş Tarihine Göre Artan</option>
              <option value="end_date" data-sort="desc">Bitiş Tarihine Göre Azalan</option>
              <option value="start_date" data-sort="asc">Başlangıç Tarihine Göre Artan</option>
              <option value="start_date" data-sort="desc">Başlangıç Tarihine Göre Azalan</option>
            </select>
          </div>
        </div>
      </div>
      <default-loading v-if="assignment_loading"></default-loading>
      <template v-else>
        <div class="pb-4 uk-grid justify-content-between" uk-grid="">
          <div class="uk-width-4-4@m uk-first-column">
            <div v-if="formattedItem" class="uk-child-width-1-4@m mt-4 uk-grid uk-first-column" uk-grid=""
              uk-scrollspy="target: > div; cls: uk-animation-slide-left-small; delay: 200">
              <div v-for="assignment in formattedItem">
                <div class="card animate-this uk-inline-clip">
                  <img :src="assignment.cover_image_link" alt="" style="min-height:200px; max-height:200px; width:100%" />
                  <div class="card-body text-center pb-3">
                    <h6 class="mb-2 assignment-name">{{ assignment.name }}</h6>
                    <h6 class="mb-0 assignment-order" v-if="assignment.repeatable">
                      {{ assignment.order_number }}
                    </h6>
                  </div>
                  <div class="card-body text-center p-3 d-flex flex-column">
                    <router-link :to="{
                      name: 'company.assignments.preview',
                      params: { id: assignment.id },
                    }" class="btn btn-secondary btn-sm mb-2">
                      <i class="uil-eye"></i>{{ $t('certification.preview') }}
                    </router-link>
                    <router-link :to="{
                      name: 'company.assignments.edit',
                      params: { id: assignment.id },
                    }" class="btn btn-info btn-sm mb-2">
                      <i class="uil-play-circle"></i> {{ $t('faq_categories.edit') }}
                    </router-link>
                    <a href="#" @click.prevent="
                      removeAssignment(assignment.id, assignment.hasAssigned)
                      " class="btn btn-secondary btn-sm mb-2">
                      <i class="uil-minus-circle"></i> {{ $t('general.delete') }}
                    </a>
                  </div>
                  <div class="card-footer py-0 border-top-0">
                    <div class="row align-items-center text-center">
                      <div class="col-12 py-3">
                        <a uk-tooltip="title: Başlangıç/Bitiş Tarihi; pos: bottom-center" class="text-muted"><i
                            class="uil-calendar-alt"></i>
                          {{ assignment.start_end }}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center">
            <pagination v-if="pagination && pagination.hasOwnProperty('links')" :pagination="pagination"
              @changePage="setPage($event)"></pagination>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import DataTable from "@/assets/components/dataTable/DataTable";
import store from "@/core/services";
import Pagination from "@/assets/components/Pagination";
import DefaultLoading from "@/view/components/loading/DefaultLoading";

import module, {
  BASE_URL,
  MODULE_NAME,
  ERROR,
  ITEMS,
  GET_ITEMS,
  DELETE_ITEM,
  LOADING,
} from "@/core/services/store/assignment.module";
import moment from "moment";
import Swal from "sweetalert2";
import { RESET_POINTS } from "@/core/services/store/company.module";
export default {
  name: "AssignmentList",
  components: { DataTable, Pagination, DefaultLoading },
  data() {
    return {
      pagination: null,
      activeItem: null,
      successMessage: null,
      errorMessages: [],
      page: 1,
      per_page: 12,
      filter: {
        search: null,
        sort: "desc",
        order_by: "created_at",
      },
    };
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }
    registerStoreModule(MODULE_NAME, module);
  },
  computed: {
    items: {
      get() {
        return store.getters[MODULE_NAME + "/" + ITEMS];
      },
      set(value) { },
    },
    assignment_loading: {
      get() {
        return store.getters[MODULE_NAME + "/" + LOADING];
      },
      set(value) { },
    },
    error: {
      get() {
        return store.getters[MODULE_NAME + "/" + ERROR];
      },
      set(value) { },
    },
    formattedItem() {
      let results = [];
      if (this.items === null || !("data" in this.items)) return [];
      this.pagination = this.items;

      this.items.data.forEach((currentItem) => {
        results.push({
          id: currentItem.id,
          name: currentItem.name,
          hasAssigned: currentItem.users_count,
          cover_image_link: currentItem.cover_image_link,
          description: currentItem.description ? currentItem.description : "-",
          order_number: currentItem.repeatable_main_item
            ? "Ana İçerik Ataması"
            : currentItem.order_number + ". Tekrarlı Atama",
          repeatable:
            currentItem.repeatable_main_item || currentItem.repeatable
              ? true
              : false,
          has_gift: currentItem.has_gift
            ? this.$t("general.yes")
            : this.$t("general.no"),
          created_at: currentItem.created_at
            ? moment(currentItem.created_at).format("DD/MM/YYYY HH:mm:ss")
            : "-",
          start_end:
            currentItem.start_date && currentItem.end_date
              ? moment(currentItem.start_date).format("DD-MM-YYYY") +
              " / " +
              moment(currentItem.end_date).format("DD-MM-YYYY")
              : "-",
        });
      });

      return results;
    },
  },
  methods: {
    getAssignmentResult() {
      let self = this;
      this.$store.dispatch(MODULE_NAME + "/" + GET_ITEMS, {
        url: BASE_URL,
        filters: {
          search: self.filter.search,
          page: self.page,
          per_page: self.per_page,
          sort: self.filter.sort,
          order_by: self.filter.order_by,
          with_users: true,
        },
      });
    },
    removeAssignment(id, hasAssignment) {
      let self = this;
      Swal.fire({
        title:
          "Atama yapılmış kullanıcılar var, silmek istediğinizden emin misiniz?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Evet",
        cancelButtonText: "Vazgeç",
        confirmButtonColor: "#e31748",
      }).then((result) => {
        if (result.isConfirmed) {
          self.$store
            .dispatch(MODULE_NAME + "/" + DELETE_ITEM, {
              url: BASE_URL + "/" + id,
            })
            .then(() => {
              if (!self.error) {
                self.successMessage = self.$t("general.successfully_deleted");
                self.getAssignmentResult();
              } else {
                self.errorMessages.push(self.error);
              }
            });
        }
      });
    },
    sortOrder(event) {
      let target = event.target;
      this.page = 1;
      this.filter.sort = target.selectedOptions[0].getAttribute("data-sort");
      this.filter.order_by = target.value;
      this.getAssignmentResult();
    },
    setPage(page, is_user = true) {
      let self = this;
      self.page = page;
      this.getAssignmentResult();
    },
    resetMessages() {
      this.successMessage = null;
      this.errorMessages = [];
    },
  },
  mounted() {
    this.getAssignmentResult();
    $(".selectpicker4").selectpicker()
  },
  watch: {
    "filter.search": function (val) {
      this.debouncedFilterSearch();
    },
    successMessage: function (val) {
      this.debouncedResetValues();
    },
    errorMessages: function (val) {
      this.debouncedResetValues();
    },
  },
  created: function () {
    this.debouncedFilterSearch = _.debounce(this.getAssignmentResult, 500);
    this.debouncedResetValues = _.debounce(this.resetMessages, 2000);
  },
};
</script>
<style scoped>
input[type="button"],
input[type="submit"],
span.btn,
.btn {
  display: inline-block;
}

.assignment-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.assignment-order {
  font-size: 12px;
  color: rgb(149, 149, 149);
}

.bootstrap-select.open .dropdown-menu {
  opacity: 1 !important;
  pointer-events: all !important;
  visibility: visible !important;
}

.inner {
  opacity: 1 !important;
  visibility: visible !important;

}

::v-deep .inner {
  opacity: 1 !important;
  visibility: visible !important;
  pointer-events: all !important;

}

::v-deep .show {
  opacity: 1 !important;
  visibility: visible !important;
}
</style>
